// store/modules/auth.js
import { statusApplicant, statusProcess, responsible } from '../utils';

let baseState = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'Ciudad',
          value: 'provider.city'
        },
        {
          text: 'Estatus del aspirante',
          value: 'provider.is_approved'
        },
        {
          text: 'Estatus del proceso',
          value: 'status'
        },
        {
          text: 'Responsable',
          value: 'responsible'
        }
      ],
      optionValue: 'provider.city',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: 1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'provider.unique_id'
        },
        {
          text: 'Ciudad',
          value: 'provider.city'
        },
        {
          text: 'Nombre',
          value: 'provider.first_name'
        },
        {
          text: 'Cédula',
          value: 'provider.document_number'
        }
      ],
      optionValue: ''
    },
    cityFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    statusApplicantFilter: {
      optionValue: [],
      options: statusApplicant,
      selectAll: false
    },
    statusProcessFilter: {
      optionValue: [],
      options: statusProcess,
      selectAll: false
    },
    responsibleFilter: {
      optionValue: [],
      options: responsible,
      selectAll: false
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

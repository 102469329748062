const EXPORT_TYPES = {
  DRIVER_PAYMENT: 1,
  BANK_PAYMENT: 2,
  DRIVER_PAYMENT_DISTRIBUTION: 3
};
const EXPORT_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  FAILED: 5
};

export { EXPORT_TYPES, EXPORT_STATUS };

export default [
  {
    path: 'direct-debit-transaction',
    name: 'direct-debit-transaction',
    meta: {
      requiresAuth: true,
      permission: 'reports/direct-debit-transaction',
      title: 'Débito Inmediato - Transacciones',
      module: 'report',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-bank-transfer'
    },
    component: () =>
      import(/* webpackChunkName: "direct-debit-transactions" */ '@/views/reports/direct-debit-transactions/directDebitTransactionLayout.vue'),
    children: [
      {
        path: '',
        name: 'direct-debit-transaction-index',
        meta: {
          permission: 'reports/direct-debit-transaction',
          requiresAuth: true,
          layout: 'admin',
          title: 'Debito Inmediato',
          module: 'report',
          icon: 'mdi-view-list',
          adminLayoutNav: true
        },
        component: () =>
          import(
            /* webpackChunkName: "'direct-debit-transaction-index" */ '@/views/reports/direct-debit-transactions/directDebitTransactionIndex.vue'
          )
      },
    ]
  }
];

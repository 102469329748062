// store/modules/auth.js

let baseState = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'Ciudad',
          value: 'city'
        },
      ],
      optionValue: 'city',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: 1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Placa',
          value: 'provider.vehicle_detail.plate_no'
        },
        {
          text: 'Marca',
          value: 'provider.vehicle_detail.brand'
        },
        {
          text: 'Modelo',
          value: 'provider.vehicle_detail.model'
        },
        {
          text: 'Año',
          value: 'provider.vehicle_detail.passing_year'
        },
      ],
      optionValue: ''
    },
    cityFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    fleetFilter: {
      optionValue: [],
      options: [],
      selectAll: false
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

// store/modules/userLoyaltyPromocodeList.js
const state = {
    filters: {
      pagination: {
        limit: 10,
        page: 1,
        pages: 1,
      },
      sortFilter: {
        options: [
          {
            text: 'Última actualización',
            value: 'updated_at'
          },
          {
            text: 'Más usos',
            value: 'code_uses'
          },
        ],
        optionValue: 'updated_at',
        sortModes: [
          {
            text: 'Ascendente',
            value: 1
          },
          {
            text: 'Descendente',
            value: -1
          }
        ],
        sortModeValue: -1,
      },
      searchFilter: {
        searchValue: '',
        options: [
          {
            text: 'Cashback',
            value: '_id'
          }
        ],
        optionValue: '',
      },
    },
    // Other states...
  };
  
  const mutations = {
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    // Other mutations...
  };
  
  const actions = {
    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters);
    },
    // Other actions...
  };
  
  const getters = {
    filters: (state) => state.filters,
    // Other getters...
  };
  
  export default {
    namespaced: true, // Important for namespacing in Vuex
    state,
    mutations,
    actions,
    getters,
  };
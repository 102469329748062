import {
  EXPORT_TYPES,
  EXPORT_STATUS
} from '@/views/export-tasks/utils/constant';

const state = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Email',
          value: 'email'
        }
      ],
      optionValue: 'unique_id',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      optionValue: ''
    },
    typeFilter: {
      searchValue: '',
      options: [
        {
          text: 'Todos',
          value: ''
        },
        {
          text: 'Driver Payment',
          value: EXPORT_TYPES.DRIVER_PAYMENT
        },
        {
          text: 'Bank Payment',
          value: EXPORT_TYPES.BANK_PAYMENT
        },
        {
          text: 'Driver Payment Distribution',
          value: EXPORT_TYPES.DRIVER_PAYMENT_DISTRIBUTION
        }
      ],
      optionValue: ''
    },
    statusFilter: {
      searchValue: '',
      options: [
        {
          text: 'Todos',
          value: ''
        },
        {
          text: 'Pending',
          value: EXPORT_STATUS.PENDING
        },
        {
          text: 'Processing',
          value: EXPORT_STATUS.PROCESSING
        },
        {
          text: 'Completed',
          value: EXPORT_STATUS.COMPLETED
        },
        {
          text: 'Cancelled',
          value: EXPORT_STATUS.CANCELLED
        },
        {
          text: 'Failed',
          value: EXPORT_STATUS.FAILED
        }
      ],
      optionValue: ''
    },
    date: [],
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filter) {
    commit('SET_FILTERS', filter);
  }
};

const getters = {
  filter: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default [
  {
    path: '/prospects',
    name: 'prospects',
    meta: {
      requiresAuth: true,
      permission: '',
      title: 'Aspirantes (Conductores y vehículos)',
      module: 'prospects',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-account-clock',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "prospects" */ '@/views/prospects/ProspectsLayout.vue'
      ),
    children: [
      // {
      //   path: 'dashboard',
      //   name: 'prospects-dashboard',
      //   meta: {
      //     adminLayoutNav: true,
      //     permission: 'prospects/access-dashboard',
      //     requiresAuth: true,
      //     layout: 'admin',
      //     title: 'Dashboard',
      //     module: 'prospects',
      //     icon: 'mdi-view-dashboard',
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "dashboard" */ '@/views/prospects/Dashboard.vue'
      //     )
      // },
      {
        path: 'on-process',
        name: 'prospects-on-process',
        meta: {
          adminLayoutNav: true,
          permission: 'prospects/access-on-process',
          requiresAuth: true,
          layout: 'admin',
          title: 'En proceso',
          module: 'prospects',
          icon: 'mdi-reload',
        },
        component: () =>
          import(
            /* webpackChunkName: "prospects-on-process" */ '@/views/prospects/OnProcessList.vue'
          )
      },
      {
        path: 'virtual-inspection',
        name: 'prospects-virtual-inspection',
        meta: {
          adminLayoutNav: true,
          permission: 'prospects/access-virtual-inspection',
          requiresAuth: true,
          layout: 'admin',
          title: 'Inspección virtual',
          module: 'prospects',
          icon: 'mdi-account-search',
        },
        component: () =>
          import(
            /* webpackChunkName: "prospects-virtual-inspection" */ '@/views/prospects/VirtualInspectionList.vue'
          )
      },
      {
        path: 'video-interview',
        name: 'prospects-video-interview',
        meta: {
          adminLayoutNav: true,
          permission: 'prospects/access-video-interview',
          requiresAuth: true,
          layout: 'admin',
          title: 'Evaluación vídeo entrevista',
          module: 'prospects',
          icon: 'mdi-eye',
        },
        component: () =>
          import(
            /* webpackChunkName: "prospects-video-interview" */ '@/views/prospects/VideoInterviewList.vue'
          )
      },
      {
        path: 'settings',
        name: 'prospects-settings',
        meta: {
          adminLayoutNav: true,
          permission: 'prospects/access-settings',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuraciones',
          module: 'prospects',
          icon: 'mdi-cog',
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/prospects/Settings.vue'
          )
      },
    ]
  }
];

// store/modules/auth.js

const state = {
  filters: {
    tabsOptions: {
      value: 'Razones de rechazo',
      options: [
        'Razones de rechazo',
        'Razones de reparación',
        'Inspección virtual - Quiz',
        'Mensajes de info',
      ],
      select: 0
    }
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

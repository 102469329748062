export default [
  {
    path: '/corporates',
    name: 'corporates',
    meta: {
      permission: 'list-corporate',
      requiresAuth: true,
      title: 'Corporativos',
      module: 'corporates',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-store',
      isBaseLayout: true
    },
    component: () => import('@/views/corporates/CorporatesLayout.vue'),
    children: [
      {
        path: '',
        name: 'corporates-list',
        meta: {
          adminLayoutNav: true,
          permission: 'list-corporate',
          requiresAuth: true,
          layout: 'admin',
          title: 'Listado',
          module: 'corporates',
          icon: 'mdi-view-list',
          // providerStatus: 'all',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () => import('@/views/corporates/CorporatesList.vue')
      }
    ]
  }
];

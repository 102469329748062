import axios from 'axios';
import store from '../store';
import router from '../router';
import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import { capitalizeFirstLetter } from '@/utils/index';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
});

service.defaults.timeoutErrorMessage = 'Timeout error';

/**
 * Enviamos el Token en cada petición axios
 */
service.interceptors.request.use(
  (config) => {
    if (!navigator.onLine) {
      return Promise.reject(new Error('No internet connection'), {
        cause: 418
      });
    }
    if (store.state.auth.user.isLoggedIn && store.state.auth.user.token) {
      config.headers['Authorization'] = `Bearer ${store.state.auth.user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class CustomError extends Error {
  constructor(message, code, detail) {
    super(message); // Llamada al constructor de la clase padre (Error)
    this.code = code;
    this.detail = detail;
    this.name = 'CustomError'; // Personaliza el nombre del error
  }
}

// response interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error || !error?.response) {
      return Promise.reject(
        new Error('503 - No response from server', { cause: 503 })
      );
    }
    if (error.message === 'Timeout error') {
      return Promise.reject(new Error('408 - Timeout error', { cause: 408 }));
    }
    const { status, data } = error.response;
    console.log(
      '⚠️⚠️⚠️Data del error inicial definido interceptado por axios:⚠️⚠️⚠️',
      data
    );
    switch (status) {
      case 400:
        if (data?.error_description) {
          return Promise.reject(
            new Error(capitalizeFirstLetter(`${data?.error_description}`), {
              cause: 400
            })
          );
        }
        return Promise.reject(error);
      case 401:
        if (!data.error_description.includes('Access token not valid')) {
          logoutAndRedirect();
          return Promise.reject(
            new Error('401 - Not authorized', { cause: 401 })
          );
        }
        try {
          handle401CaseRefresh();
        } catch (error) {
          console.log('🚀 ~ file: axios.js:52 ~ error:', error);
          logoutAndRedirect();
          return Promise.reject(
            new Error('401 - Not authorized', { cause: 401 })
          );
        } finally {
          return;
        }
      case 403:
        // logoutAndRedirect();
        if (
          !error?.response?.data?.success &&
          error?.response?.data?.error_code === '3008'
        ) {
          return Promise.reject(
            new Error('Credenciales inválidas', { cause: 403 })
          );
        }
        redirectToDashboards();
        return Promise.reject(new Error('403 - Access denied', { cause: 403 }));

      case 404:
        if (error.response?.data?.byteLength) {
          //Excel vacio respuesta 404 de braulio
          return Promise.reject(
            new Error('Exportación vacía', { cause: 'empty-export' })
          );
        }
        return Promise.reject(new Error('404 - not found', { cause: 404 }));

      case 500:
        const errorMessage =
          data?.error_description || '500 - internal server error';
        console.error('Error generating XLSX:', errorMessage);
        return Promise.reject(new Error(errorMessage, { cause: 500 }));

      default:
        return Promise.reject(
          new Error(`${status} - Error in response`, { cause: status })
        );
    }
  }
);

const handle401CaseRefresh = async () => {
  try {
    const dialogInstance = await Vue.prototype.$dialog.confirm({
      text: 'Sesión expirada: ¿Desea extender su sesión?',
      title: 'Atención',
      actions: {
        true: 'Sí',
        false: 'No'
      },
      waitForResult: false
    });

    setTimeout(() => {
      dialogInstance.close();
    }, 30000); //30 segundos

    const userChoice = await dialogInstance.wait();

    if (!userChoice) {
      logoutAndRedirect();
      return;
    }

    store.dispatch('setApp', {
      isLoadingOverlay: true
    });
    const refreshResponse = await refreshAccessToken();

    if (!refreshResponse?.data?.success) {
      throw new Error('No refresh response');
    }

    const { token, refresh } = refreshResponse.data.data;
    const decodedToken = jwtDecode(token);
    let { email, id, username, permissions = [], adminType } = decodedToken;
    // no admin type in refresh response
    if (adminType === undefined || adminType === null) {
      adminType = store.state.auth.user.userDetails.adminType;
    }
    permissions = permissions.filter((permission) => permission !== null);
    store.dispatch('auth/setUser', {
      isLoggedIn: true,
      token: token,
      refresh: refresh,
      userDetails: {
        username,
        email,
        id,
        permissions,
        adminType
      }
    });

    Vue.prototype.$dialog.notify.success('Sesión extendida');
  } catch (error) {
    return Promise.reject(error);
  } finally {
    store.dispatch('setApp', {
      isLoadingOverlay: false
    });
  }
};

const logoutAndRedirect = () => {
  Vue.prototype.$dialog.notify.info('Sesión expirada');

  store.dispatch('auth/setUser', {
    isLoggedIn: false,
    token: null,
    refresh: null,
    userDetails: {}
  });
  if (router.history.current.name !== 'login') {
    router.push({ name: 'login' });
  }
};

const refreshAccessToken = async () => {
  try {
    return await Vue.prototype.$axios.post(
      '/authentication/admin/refreshToken',
      {
        refresh: store.state.auth.user.refresh
      }
    );
  } catch (error) {
    return Promise.reject(error);
  }
};

const redirectToDashboards = () => {
  Vue.prototype.$dialog.notify.info(
    'No tienes permiso para acceder a este recurso'
  );
  if (router.history.current.name !== 'dashboards') {
    router.push({ name: 'dashboards' });
  }
};

export default service;

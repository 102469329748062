export const statusApplicant = [
  {
    text: 'Aprobado',
    value: 1,
  },
  {
    text: 'No aprobado',
    value: 0,
  },
];

export const statusProcess = [
  {
    text: 'Registro de proveedor',
    value: 'providerRegistered',
  },
  {
    text: 'Registro de vehículo',
    value: 'vehicleRegistered',
  },
  {
    text: 'Carnet en verificación',
    value: 'documentVerifying',
  },
  {
    text: 'Inspección virtual pendiente',
    value: 'virtualInspectionPending',
  },
  {
    text: 'Inspección virtual en revisión',
    value: 'virtualInspectionReviewing',
  },
  {
    text: 'Inspección virtual en reparaciones',
    value: 'virtualInspectionRepairing',
  },
  {
    text: 'Vídeo entrevista pendiente',
    value: 'interviewVideoPending',
  },
  {
    text: 'Vídeo entrevista en revisión',
    value: 'interviewVideoReviewing',
  },
  {
    text: 'RCV pendiente',
    value: 'rcvPending',
  },
  {
    text: 'Inducción pendiente',
    value: 'inductionPending',
  },
  {
    text: 'Inspección presencial por agendar',
    value: 'inPersonInspectionSchedulePending',
  },
  {
    text: 'Inspección presencial por asistir',
    value: 'inPersonInspectionAttending',
  },
  {
    text: 'Inspección presencial en reparaciones',
    value: 'inPersonInspectionRepairing',
  },
  {
    text: 'Inspección presencial aprobada',
    value: 'inPersonInspectionApproved',
  },
];

export const statusProcessOverview = [
  {
    text: 'Registro de proveedor',
    value: 'providerRegistered',
  },
  {
    text: 'Registro de vehículo',
    value: 'vehicleRegistered',
  },
  {
    text: 'Carnet en verificación',
    value: 'documentVerifying',
  },
  {
    text: 'Inspección virtual',
    value: 'virtualInspection',
  },
  {
    text: 'Vídeo entrevista',
    value: 'interviewVideo',
  },
  {
    text: 'Inducción pendiente',
    value: 'inductionPending',
  },
  {
    text: 'Inspección presencial',
    value: 'inPersonInspectionSchedule',
  },

];

export const responsible = [
  {
    text: 'Conductor',
    value: 'provider',
  },
  {
    text: 'Admin',
    value: 'admin',
  },
  {
    text: 'Ridery',
    value: 'ridery',
  },
];

export const requestPhotos = [
  {
    text: 'Foto frontal',
    value: 'front',
  },
  {
    text: 'Parte trasera',
    value: 'rear',
  },
  {
    text: 'Maletero abierto',
    value: 'trunk',
  },
  {
    text: 'Lateral derecho',
    value: 'sideLeft',
  },
  {
    text: 'Lateral izquierdo',
    value: 'sideRight',
  },
  {
    text: 'Asientos delanteros',
    value: 'frontSeats',
  },
  {
    text: 'Asientos traseros',
    value: 'rearSeats',
  },
  {
    text: 'Tablero',
    value: 'dashboard',
  },
];

export const statusVirtualInspection = {
  FOR_REPAIR: 'forRepair',
  APPROVED: 'approved',
  REJECTED: 'rejected',
}

export const getResponsible = (value) => {
  return responsible.find((item) => item.value === value)?.text;
}

export const getStatusApplicant = (value) => {
  return statusApplicant.find((item) => item.value === value)?.text;
}

export const getStatusProcess = (value) => {
  return statusProcess.find((item) => item.value === value)?.text;
}

export const getStatusProcessOverview = (value) => {
  return statusProcessOverview.find((item) => item.value === value)?.text;
}

export const getRequestPhotos = (value) => {
  return requestPhotos.find((item) => item.value === value)?.text;
}